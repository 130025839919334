import { useNavigate } from 'react-router-dom';
import './CommentModal.scss';

const CommentModal = ({ onClose, title, commentStatus, comment, setComment, onSubmit, bookingId }) => {
  const navigate = useNavigate();
  
  // Handle redirection after successful comment submission
  const handleRedirection = () => {
    setTimeout(() => {
      // Redirect back to the booking page
      navigate(`/booking/${bookingId}`);
      // Refresh the page to ensure the timeline shows the new comment
      window.location.reload();
    }, 2000); // 2-second delay for the success message
  };
  
  const renderContent = () => {
    switch (commentStatus) {
      case 'submitting':
        return <p>Submitting your comment...</p>;
      case 'success':
        handleRedirection(); // Trigger redirection after successful submission
        return <p>Comment submitted successfully! Redirecting...</p>;
      case 'error':
        return <p>Error submitting comment. Please try again.</p>;
      default: // 'idle' and any other case
        return (
          <>
            <textarea
              className='modalTextarea'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your comment"
            />
            <button onClick={onSubmit} className="modalSubmitButton">Submit Comment</button>
          </>
        );
    }
  };

  return (
    <div className="commentModal">
      <div className="modalContent">
        <button onClick={onClose} className="modalCloseIcon" aria-label="Close">&times;</button>
        <h2 className="modalTitle">{title}</h2>
        <div className="modalBody">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default CommentModal;
