import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './BookingForm.scss';
import axios from 'axios';
import { TextField, Checkbox, Button, FormControlLabel, Grid, Autocomplete } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { useAuth } from '../../contexts/authContext';
import debounce from 'lodash.debounce';

const BookingForm = () => {
    const navigate = useNavigate();
    const { token } = useAuth();
    const [formData, setFormData] = useState({
        from: null,
        to: null,
        roundTrip: false,
        departureDate: null,
        returnDate: null,
        adults: 1,
        children: 0,
        infants: 0,
    });
    const [locations, setLocations] = useState([]);

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Debounce the autocomplete function to avoid multiple API calls
    const debounceAutocomplete = useCallback(
        debounce(async (query) => {
            if (query.trim().length === 0) {
                return; // Prevent empty queries from being sent
            }
            try {
                //console.log("Frontend query:", query); // Ensure the query is correctly logged here
                const response = await axios.get(`/autocomplete`, {
                    params: { query }
                });

                const data = Array.isArray(response.data) ? response.data : [];
                setLocations(data);
            } catch (error) {
                //console.error('Autocomplete failed:', error);
                setLocations([]); // Set locations to an empty array on error
            }
        }, 300), // Adjust the debounce time as needed
        []
    );

    const validateForm = () => {
        const { from, to, departureDate, returnDate, roundTrip, adults, children, infants } = formData;

        if (!from || !to) {
            alert('Please select valid departure and arrival locations.');
            return false;
        }

        if (!departureDate) {
            alert('Please select a departure date.');
            return false;
        }

        if (roundTrip && !returnDate) {
            alert('Please select a return date for round trips.');
            return false;
        }

        if (new Date(departureDate) <= new Date()) {
            alert('Departure date must be in the future.');
            return false;
        }

        if (roundTrip && new Date(returnDate) <= new Date(departureDate)) {
            alert('Return date must be after the departure date.');
            return false;
        }

        if (adults > 9) {
            alert('Number of adults cannot exceed 9 per booking.');
            return false;
        }

        if (infants > adults) {
            alert('Number of infants cannot exceed the number of adults.');
            return false;
        }

        if (children > adults * 2) {
            alert('Number of children cannot be more than double the number of adults.');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!token) {
            console.error('User is not authenticated.');
            return;
        }

        if (!validateForm()) {
            return;
        }

        const fullFormData = {
            ...formData,
            departureDate: formData.departureDate ? formData.departureDate.toLocaleDateString('en-CA') : null,
            returnDate: formData.returnDate ? formData.returnDate.toLocaleDateString('en-CA') : null,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        try {
            const response = await axios.post(`/create-booking`, {
                ...fullFormData,
                token,
            });

            if (response.data.success) {
                navigate(`/booking/${response.data.booking_id}`);
            } else {
                console.error('Booking failed:', response.data.message);
            }
        } catch (error) {
            console.error('Booking failed:', error);
        }
    };

    if (!token) {
        return <div>Please log in to make a booking.</div>;
    }

    return (
        <div className="bookingForm">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={locations}
                                getOptionLabel={(option) => `${option.name} (${option.iataCode})`}
                                onInputChange={(event, newInputValue) => debounceAutocomplete(newInputValue)}
                                value={formData.from}
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, from: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} label="From" />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={locations}
                                getOptionLabel={(option) => `${option.name} (${option.iataCode})`}
                                onInputChange={(event, newInputValue) => debounceAutocomplete(newInputValue)}
                                value={formData.to}
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, to: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} label="To" />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.roundTrip}
                                        onChange={handleChange}
                                        name="roundTrip"
                                    />
                                }
                                label="Round Trip"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label="Departure Date"
                                value={formData.departureDate}
                                onChange={(newValue) => setFormData({ ...formData, departureDate: newValue })}
                                textField={<TextField />}
                                required
                            />
                        </Grid>
                        {formData.roundTrip && (
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Return Date"
                                    value={formData.returnDate}
                                    onChange={(newValue) => setFormData({ ...formData, returnDate: newValue })}
                                    textField={<TextField />}
                                    required
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                label="Adults"
                                type="number"
                                name="adults"
                                value={formData.adults}
                                onChange={handleChange}
                                inputProps={{ min: 1, max: 9 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Children"
                                type="number"
                                name="children"
                                value={formData.children}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Infants"
                                type="number"
                                name="infants"
                                value={formData.infants}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" className="submitBtn">Submit</Button>
                </form>
            </LocalizationProvider>
        </div>
    );
};

export default BookingForm;
