import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "./BookingOptionGroup.scss"
import BookingOption from '../bookingOption/BookingOption';
import CommentModal from '../commentModal/CommentModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useAuth } from '../../contexts/authContext'; // Adjust path as necessary

const BookingOptionGroup = ({ optionGroup, statusId }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [commentStatus, setCommentStatus] = useState('idle'); // New state to track comment status
  const { token } = useAuth(); // Access token from auth context
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    setConfirmOpen(false);
    try {
      const response = await fetch(`/confirm-booking-option`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          booking_id: optionGroup.booking_id,
          option_id: selectedOption.option_id,
          token: token, // Include the token in the body
        })
      });
      const data = await response.json();
      if (data.success) 
      {
        console.log('Booking confirmed!');
        // Set a timeout before navigating to refresh the page and show the updated booking
        setTimeout(() => 
        {
          navigate(`/booking/${optionGroup.booking_id}`);
          window.location.reload();
        }, 2000); // Adjust the delay here as needed (3000 milliseconds = 3 seconds)
      } else {
        alert('Failed to confirm booking: ' + data.message);
      }
    } catch (error) {
      console.error('Error confirming booking:', error);
      alert('Error confirming booking');
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRequestChangeClick = () => {
    setIsModalOpen(true);
    setCommentStatus('idle'); // Reset the comment status when opening the modal
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const submitComment = async () => {
    setCommentStatus('submitting'); // Update status to submitting
    try {
      console.log(optionGroup);
      const response = await fetch(`/submit-change-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          booking_id: optionGroup.booking_id,
          comment: comment,
          status_id: statusId, // Assuming selectedOption holds the status_id
          token: token, // Include the token in the body
        }),
      });
      
      const data = await response.json();
      if (data.success) {
        setCommentStatus('success'); // Update status to success
      } else {
        setCommentStatus('error'); // Update status to error
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
      setCommentStatus('error'); // Update status to error in case of exception
    }
  };

  return (
    <div className='bookingOptionGroup'>
      {optionGroup.options.map(option => (
        <BookingOption
          key={option.option_id}
          option={option}
          isSelected={selectedOption === option.option_id}
          onSelect={() => handleOptionChange(option)}
        />
      ))}
      <button className="requestChangeButton" onClick={handleRequestChangeClick}>
        Request Change
      </button>
      {isModalOpen && (
        <CommentModal
          onClose={() => setIsModalOpen(false)}
          title="Request a Change"
          commentStatus={commentStatus}
          setComment={setComment}
          comment={comment}
          onSubmit={submitComment} // Passing submitComment directly to the modal
          bookingId={optionGroup.booking_id}
        />
      )}
      {/* Confirmation Dialog */}
        <Dialog
          open={confirmOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Booking"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to select this option?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirm} autoFocus>
              Confirm
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookingOptionGroup;
