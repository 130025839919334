import React from 'react';
import './BookingOption.scss';

const BookingOption = ({ option, onSelect }) => {
  
  // Render each flight segment as a list
  const flightSegments = option.items.map((item, index) => (
    <li key={item.item_id} className={`flightSegment ${item.flight_direction}`}>
      <img src="../images/airlines/TK.png" alt={`${item.airlines} flight`} className="airlineLogo"/>
      <div className="info">
        <div className="departure">
          <span className="time">{item.departure_time}</span>
          <span className="airport">{item.departure_airport}</span>
        </div>
        <div className="flightDuration">
          <span className="duration">{item.flight_duration}</span>
          <div className="line"> 
            <img src='../flight.svg' className='flightIcon'/>
          </div>
          <span className="duration">direct</span>
        </div>
        <div className="arrival">
          <span className="time">{item.arrival_time}</span>
          <span className="airport">{item.arrival_airport}</span>
        </div>
      </div>
    </li>
  ));

  return (
    <div className="bookingOption">
      <ul className="flightSegmentsList">{flightSegments}</ul>
      <div className="selectAndConfirm">
        <span className="price">{`€${option.option_price}`}</span>
        <button className="select" onClick={() => onSelect(option)}>Select</button>
      </div>
    </div>
  );
};

export default BookingOption;
