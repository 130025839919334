import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './DataTable.scss';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const DataTable = ({ columns, rows, getRowId  }) => {
    const { t } = useTranslation();
    // Translate column headers
    const translatedColumns = columns.map(column => ({
        ...column,
        headerName: t(`dataTable.header.${column.field}`, column.headerName)
    }));

    const actionColumn = {
        field: "action",
        headerName: t("dataTable.view", "View"),
        width: 150,
        renderCell: (params) => {
          return (
            <div className="action">
              <Link to={`/booking/${params.row.booking_id}`}>
              <img src="/view.svg" alt={t("dataTable.view", "view")} />
              </Link>
            </div>
          );
        },
      };
  return (
    <div className="dataTable">
        <DataGrid
            className="dataGrid"
            rows={rows}
            columns={[...translatedColumns, actionColumn]}
            pageSize={25}
            disableCheckboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableDensitySelector
            disableColumnSelector
            getRowId={getRowId}  // Pass getRowId to the DataGrid component
            slots={{ toolbar: GridToolbar }}
            slotProps={{
            toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
            },
            }}
        />
    </div>
  );
};

export default DataTable;
