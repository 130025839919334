import React from 'react';
import { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Card, CardContent, Typography, Autocomplete, InputAdornment, Grid, Divider, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import './CreateBookingOptionsGroup.scss';
import { airports, airlines } from '../../data.ts';

const CreateBookingOptionsGroups = () => {
    const { id: bookingId } = useParams();
    const [bookingOptions, setBookingOptions] = useState([]);
    const [isRoundTrip, setIsRoundTrip] = useState(null);  // Initialize as null to differentiate uninitialized state
    const [fetching, setFetching] = useState(true); // New state to track fetching status
    const [userTimezone, setUserTimezone] = useState(""); // Store the user's timezone
   
    useEffect(() => {
        // Get the user's local timezone
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setUserTimezone(localTimezone);
    }, []); // Run only once on component mount

    useEffect(() => {
        const fetchBookingDetails = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.post(`/get-booking-by-id-and-operator`, {
                    booking_id: bookingId,
                    token: token // Include token in the body
                });
                
                if (response.data.success) {
                    console.log(response.data.bookingDetails);
                    setIsRoundTrip(!!response.data.bookingDetails.roundtrip);
                    setFetching(false); // Indicate that fetching is complete
                } else {
                    console.error('Failed to fetch booking details:', response.data.message);
                    setFetching(false);
                }
            } catch (error) {
                console.error('Error:', error);
                setFetching(false);
            }
        };

        fetchBookingDetails();
    }, [bookingId]);

    // Separate effect to add a new booking option when the round trip info becomes available
    useEffect(() => {
        if (isRoundTrip !== null && !fetching) { // Check if round trip info is available and fetching is complete
            addNewBookingOption(isRoundTrip);
        }
    }, [isRoundTrip, fetching]);

    //testing purposes
    useEffect(() => {
        console.log('Booking Options Updated:', bookingOptions);
    }, [bookingOptions]);

    const handleInputChange = (optionIndex, flightIndex = null, field, value, isReturn = false) => {
        //console.log(`Change Initiated: ${isReturn ? 'Return' : 'Outbound'} FlightIndex: ${flightIndex}, Field: ${field}, Value: ${value}`);
        setBookingOptions(currentOptions => {
            return currentOptions.map((option, index) => {
                if (index === optionIndex) {
                    if (flightIndex === null) {
                        // Handle option-level changes directly on the option object
                        return { ...option, [field]: value };
                    } else {
                        // Handle changes inside either the 'return' or 'outbound' flights array
                        let flightsKey = isReturn ? 'return' : 'outbound';
                        //console.log(`Before Update ${flightsKey}:`, option[flightsKey].flights);
                        // Access the flights array within the 'return' or 'outbound' object
                        let updatedFlights = [...option[flightsKey].flights];
                        let updatedFlight = { ...updatedFlights[flightIndex] };
    
                        // Update the specific field inside the flight object
                        updatedFlight[field] = value;
                        updatedFlights[flightIndex] = updatedFlight;
    
                        //console.log(`After Update ${flightsKey}:`, updatedFlights);
                        // Return the updated option with the modified flights array
                        return { 
                            ...option, 
                            [flightsKey]: {
                                ...option[flightsKey],
                                flights: updatedFlights
                            } 
                        };
                    }
                }
                return option;
            });
        });
    };
    
    
    const handleStopsChange = (optionIndex, flightType, newStopsValue) => {
        setBookingOptions(currentOptions => {
            return currentOptions.map((option, index) => {
                if (index === optionIndex) {
                    let updatedFlights = [...option[flightType].flights];
                    //console.log(updatedFlights);

                    // Convert newStopsValue to a number to ensure numeric operations
                    const stopsCount = Number(newStopsValue) + 1;
    
                    // Increasing the number of stops
                    while (updatedFlights.length < stopsCount) {
                        const lastSegment = updatedFlights[updatedFlights.length - 1];
                        const { stops, ...lastSegmentWithoutStops } = lastSegment;
                        updatedFlights.push(lastSegmentWithoutStops);
                    }
    
                    // Decreasing the number of stops
                    while (updatedFlights.length > stopsCount) {
                        updatedFlights.pop();
                    }
    
                    // Update the entire flightType object (outbound or return) with the new flights array and the updated stops count
                    return {
                        ...option,
                        [flightType]: {
                            ...option[flightType],
                            flights: updatedFlights,
                            stops: Number(newStopsValue) // Update the stops at the segment level
                        }
                    };
                }
                return option;
            });
        });
    };
    
      
    const addNewBookingOption = (isTripRound) => {
        const newOption = {
            outbound: {
                stops: 0,
                flights: [{
                    airline: null,
                    departureAirport: null,
                    arrivalAirport: null,
                    departureTime: null,
                    arrivalTime: null,
                    duration: '',
                }]
            },
            return: isTripRound ? {
                stops: 0,
                flights: [{
                    airline: null,
                    departureAirport: null,
                    arrivalAirport: null,
                    departureTime: null,
                    arrivalTime: null,
                    duration: '',
                }]
            } : {
                stops: null,
                flights: [{
                    airline: null,
                    departureAirport: null,
                    arrivalAirport: null,
                    departureTime: null,
                    arrivalTime: null,
                    duration: '',
                }]
            },
        };
        //console.log('Adding new booking option:', newOption);
        setBookingOptions(currentOptions => [...currentOptions, newOption]);
    };
    
    const submitBookingOptions = async () => {
        console.log('Submitting', bookingOptions);
        
        const token = localStorage.getItem('token');
        const payload = {
            booking_id: bookingId,
            options: bookingOptions, // Your bookingOptions state
            timezone: userTimezone,  // Pass the user's timezone
            token: token // Include token in the body
        };

        try {
            const response = await axios.post(`/submit-booking-options`, payload);

            if (response.data.success) {
                // Handle success
                console.log('Booking options submitted successfully');
                // Navigate to the booking details page on success
                Navigate(`/booking/${response.data.booking_id}`); // Redirect to the booking detail page
                // You can redirect or update state here as needed
            } else {
                // Handle failure
                console.error('Failed to submit booking options:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className="createBookingOptionsGroups">
            
            {bookingOptions.map((option, optionIndex) => (
                <Card key={optionIndex} className="card">
                    <CardContent>
                        <Typography variant="h5">Booking Option {optionIndex + 1}</Typography>
                        <Grid container spacing={2}>
                            {/* Outbound Flight Fields */}
                            <Grid item xs={12}>
                            <Typography variant="h6">Outbound Flight</Typography>
                            <TextField
                                label="Number of Stops - Outbound"
                                type="text" // Changed to 'text' to disable native number input functionalities
                                value={option.outbound.stops}
                                onChange={(e) => handleStopsChange(optionIndex, 'outbound', e.target.value)}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Button
                                                onClick={() => handleStopsChange(optionIndex, 'outbound', Math.max(0, option.outbound.stops - 1))}
                                                size="small"
                                            >
                                                -
                                            </Button>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                onClick={() => handleStopsChange(optionIndex, 'outbound', Math.min(3, option.outbound.stops + 1))}
                                                size="small"
                                            >
                                                +
                                            </Button>
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        readOnly: true,  // Ensures the field cannot be typed into directly
                                    }
                                }}
                            />
                            </Grid>
                            {/* Iterating through the outbound flight fields */}
                            {option.outbound.flights.map((flight, flightIndex) => (
                                <React.Fragment key={`outbound-flight-${optionIndex}-${flightIndex}`}>
                                    {Object.entries(flight).map(([field, value], fieldIndex) => {
                                        // Generate a unique key by prepending "outbound-"
                                        const key = `outbound-${field}-${flightIndex}-${fieldIndex}`;
                                        // Use this key for your Grid or any top-level component in map
                                        // Autocomplete for Airline - Outbound
                                        if (field === 'airline') {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} key={key}>
                                                    <Autocomplete
                                                        fullWidth
                                                        options={airlines}
                                                        getOptionLabel={(option) => option ? `${option.label || ''} (${option.code || ''})` : ''}
                                                        value={value}
                                                        onChange={(event, newValue) => handleInputChange(optionIndex, flightIndex, field, newValue)}
                                                        renderInput={(params) => <TextField {...params} label="Airline" />}
                                                    />
                                                </Grid>
                                            );
                                        // Autocomplete for Airports - Outbound
                                        } else if (field === 'departureAirport' || field === 'arrivalAirport') {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} key={key}>
                                                    <Autocomplete
                                                        fullWidth
                                                        options={airports}
                                                        getOptionLabel={(option) => option ? `${option.label || ''} (${option.id || ''})` : ''}
                                                        value={value}
                                                        onChange={(event, newValue) => handleInputChange(optionIndex, flightIndex, field, newValue)}
                                                        renderInput={(params) => <TextField {...params} label={field.charAt(0).toUpperCase() + field.slice(1)} />}
                                                    />
                                                </Grid>
                                            );
                                        }
                                        // TimePicker for departureTime and arrivalTime - Outbound
                                        else if (field === 'departureTime' || field === 'arrivalTime') {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} key={key}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            label={field.charAt(0).toUpperCase() + field.slice(1)}
                                                            value={value}
                                                            onChange={(newValue) => handleInputChange(optionIndex, flightIndex, field, newValue, false)}  // Updated line
                                                            textField={<TextField fullWidth/>}
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                            }}
                                                            sx={{ width: '100%' }} // This ensures the TimePicker takes full width
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            );
                                        }
                                        else if (field === 'duration') {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} key={key} container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            select
                                                            label="Hours"
                                                            value={Math.floor(value / 60)} // Assuming value is in minutes
                                                            onChange={(e) => handleInputChange(optionIndex, flightIndex, field, (Number(e.target.value) * 60) + (value % 60))}
                                                            fullWidth
                                                        >
                                                            {[...Array(24).keys()].map(hour => (
                                                                <MenuItem key={`${key}-hour-${hour}`} value={hour}>
                                                                    {hour} Hour(s)
                                                                </MenuItem>
                                                            ))}
                                                            
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            select
                                                            label="Minutes"
                                                            value={value % 60} // Assuming value is in minutes
                                                            onChange={(e) => handleInputChange(optionIndex, flightIndex, field, (Math.floor(value / 60) * 60) + Number(e.target.value))}
                                                            fullWidth
                                                        >
                                                            {[...Array(60).keys()].map(minute => (
                                                                <MenuItem key={`${key}-minute-${minute}`} value={minute}>
                                                                    {minute} Minute(s)
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                        
                                        // Regular TextField for other fields
                                        else if (field !== 'price') {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} key={key}>
                                                    <TextField
                                                        fullWidth
                                                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                                                        value={value}
                                                        onChange={(e) => handleInputChange(optionIndex, field, e.target.value)}
                                                    />
                                                </Grid>
                                            );
                                        }
                                return null;
                                    })}
                                    {flightIndex < option.outbound.flights.length - 1 && <div className='dividerSection'><Divider className='bookingStopsOptionDivider' /><span>Stop ({flightIndex + 1})</span><Divider className='bookingStopsOptionDivider' /></div>}  
                                </React.Fragment>
                            ))}
                            {/* Return Flight Fields */}
                            {isRoundTrip && option.return && option.return.flights.length > 0 && (
                                <>
                                    <Divider className='bookingOptionDivider'/>  {/* Material-UI Divider */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Return Flight</Typography>
                                        <TextField
                                            label="Number of Stops - Return"
                                            type="text" // Changed to 'text' to disable native number input functionalities
                                            value={option.return.stops}
                                            onChange={(e) => handleStopsChange(optionIndex, 'return', e.target.value)}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Button
                                                            onClick={() => handleStopsChange(optionIndex, 'return', Math.max(0, option.return.stops - 1))}
                                                            size="small"
                                                        >
                                                            -
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button
                                                            onClick={() => handleStopsChange(optionIndex, 'return', Math.min(3, option.return.stops + 1))}
                                                            size="small"
                                                        >
                                                            +
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    readOnly: true,  // Ensures the field cannot be typed into directly
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {option.return.flights.map((flight, flightIndex) => (
                                        <React.Fragment key={`return-flight-${optionIndex}-${flightIndex}`}>
                                            {Object.entries(flight).map(([field, value], fieldIndex) => {
                                                // Generate a unique key by prepending "return-"
                                                const key = `return-${field}-${flightIndex}-${fieldIndex}`;
                                                // Field rendering logic for return flights
                                                // Autocomplete for Airline - Return
                                                if (field === 'airline') {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={key}>
                                                            <Autocomplete
                                                                fullWidth
                                                                options={airlines}
                                                                getOptionLabel={(option) => option ? `${option.label || ''} (${option.code || ''})` : ''}
                                                                value={value}
                                                                onChange={(event, newValue) => handleInputChange(optionIndex, flightIndex, field, newValue, true)}
                                                                renderInput={(params) => <TextField {...params} label="Airline" />}
                                                            />
                                                        </Grid>
                                                    );
                                                // Autocomplete for Airports - Return
                                                } else if (field === 'departureAirport' || field === 'arrivalAirport') {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={key}>
                                                            <Autocomplete
                                                                fullWidth
                                                                options={airports}
                                                                getOptionLabel={(option) => option ? `${option.label || ''} (${option.id || ''})` : ''}
                                                                value={value}
                                                                onChange={(event, newValue) => handleInputChange(optionIndex, flightIndex, field, newValue, true)}
                                                                renderInput={(params) => <TextField {...params} label={field.charAt(0).toUpperCase() + field.slice(1)} />}
                                                            />
                                                        </Grid>
                                                    );
                                                }
                                                // TimePicker for departureTime and arrivalTime - Return
                                                else if (field === 'departureTime' || field === 'arrivalTime') {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={key}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <TimePicker
                                                                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                                                                    value={value}
                                                                    onChange={(newValue) => handleInputChange(optionIndex, flightIndex, field, newValue, true)} // Updated line
                                                                    textField={<TextField fullWidth/>}
                                                                    viewRenderers={{
                                                                        hours: renderTimeViewClock,
                                                                        minutes: renderTimeViewClock,
                                                                    }}
                                                                    sx={{ width: '100%' }} // This ensures the TimePicker takes full width
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    );
                                                }
                                                else if (field === 'duration') {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={key} container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    select
                                                                    label="Hours"
                                                                    value={Math.floor(value / 60)} // Assuming value is in minutes
                                                                    onChange={(e) => handleInputChange(optionIndex, flightIndex, field, (Number(e.target.value) * 60) + (value % 60), true)}
                                                                    fullWidth
                                                                >
                                                                    {[...Array(24).keys()].map(hour => (
                                                                        <MenuItem key={`${key}-hour-${hour}`} value={hour}>
                                                                            {hour} Hour(s)
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    select
                                                                    label="Minutes"
                                                                    value={value % 60} // Assuming value is in minutes
                                                                    onChange={(e) => handleInputChange(optionIndex, flightIndex, field, (Math.floor(value / 60) * 60) + Number(e.target.value), true)}
                                                                    fullWidth
                                                                >
                                                                    {[...Array(60).keys()].map(minute => (
                                                                        <MenuItem key={`${key}-minute-${minute}`} value={minute}>
                                                                            {minute} Minute(s)
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                                // Regular TextField for other fields
                                                else if (field !== 'price') {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={key}>
                                                            <TextField
                                                                fullWidth
                                                                label={field.charAt(0).toUpperCase() + field.slice(1)}
                                                                value={value}
                                                                onChange={(e) => handleInputChange(optionIndex, flightIndex, field, e.target.value, true)}
                                                            />
                                                        </Grid>
                                                    );
                                                }
                                                return null;

                                            })}
                                            {flightIndex < option.return.flights.length - 1 && <div className='dividerSection'><Divider className='bookingStopsOptionDivider' /><span>Stop ({flightIndex + 1})</span><Divider className='bookingStopsOptionDivider' /></div>}  
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                            {/* Price Field - Only for outbound since roundtrip price is handled separately */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Price"
                                    type="number"
                                    fullWidth
                                    value={option.price || ''}  // Ensure the value is always defined
                                    onChange={(e) => handleInputChange(optionIndex, null, 'price', e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
            <div className="buttonsContainer">
                <Button className="button primary" onClick={() => addNewBookingOption(isRoundTrip)}>Add Another Booking Option</Button>
                <Button className="button secondary" onClick={submitBookingOptions}>Submit Booking Options</Button>
            </div>
        </div>
    );
    
};

export default CreateBookingOptionsGroups;
