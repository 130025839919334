import { useNavigate } from 'react-router-dom';
import './MsgTimelineItem.scss';
import { useAuth } from '../../contexts/authContext'; // Adjust path as necessary

const MsgTimelineItem = ({ item, enableButton, onOptionClick, formatDate, index }) => {
  const { userType, token } = useAuth(); // Access userType and token from auth context
  const navigate = useNavigate(); // Hook to handle navigation

  const handlePreviewPassports = () => {
    navigate(`/preview-passports/${item.booking_id}`); // Navigate to the passport gallery page
  };

  const handleUploadTickets = () => {
    navigate(`/booking/${item.booking_id}/upload-tickets`); // Navigate to the passport upload page
  };

  const handleDownloadTickets = async () => {
    try {
      const response = await fetch(`/ticket-list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          booking_id: item.booking_id,
          token: token // Include the token in the body
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to fetch ticket list:', errorText);
        return;
      }

      const ticketFiles = await response.json();
      console.log('Ticket files:', ticketFiles); // Log the ticket files response

      if (!Array.isArray(ticketFiles)) {
        console.error('Expected an array of ticket files');
        return;
      }

      for (const filename of ticketFiles) {
        const ticketResponse = await fetch(`/download-ticket`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            booking_id: item.booking_id,
            filename,
            token: token // Include the token in the body
          })
        });

        if (!ticketResponse.ok) {
          const errorText = await ticketResponse.text();
          console.error('Ticket download error:', errorText);
          continue;
        }

        const blob = await ticketResponse.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading tickets:', error);
    }
  };

  return (
    <div className="msgTimelineItem">
      {enableButton && (
        <button className="select" onClick={onOptionClick} disabled={!enableButton}>
          Create Booking Group of Options
        </button>
      )}
      {userType === 'operator' && item.status === 'preparing your tickets' && (
        <>
          <button onClick={handlePreviewPassports}>Preview Passports</button>
          <button onClick={handleUploadTickets} disabled={index !== 0}>Upload Tickets</button>
        </>
      )}
      {userType === 'client' && item.status === 'booked' && (
        <button onClick={handleDownloadTickets}>Download Tickets</button>
      )}
      <p className="statusDescription">{item.status}</p>
      <p className="statusComment">{item.comment}</p>
      <p className="statusTimestamp">{formatDate(item.timestamp)}</p>
    </div>
  );
};

export default MsgTimelineItem;
