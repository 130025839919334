import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./OperatorMenu.scss";
import { operatorMenu } from "../../data.ts";

const OperatorMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="menu">
      {operatorMenu.map((item) => (
        <div className="item" key={item.id}>
          <span className="title">{t(item.title)}</span>
          {item.listItems.map((listItem) => (
            <Link to={listItem.url} className="listItem" key={listItem.id}>
              <img src={listItem.icon} alt="" />
              <span className="listItemTitle">{t(listItem.title)}</span>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default OperatorMenu;