import { useNavigate } from 'react-router-dom';
import './TimelineItem.scss';
import BookingOptionGroup from '../bookingOptionGroup/BookingOptionGroup';
//import { useAuth } from '../../contexts/authContext'; // Import useAuth hook
import MsgTimelineItem from '../msgTimelineItem/MsgTimelineItem';
import ActionTimelineItem from '../actionTimelineItem/ActionTimelineItem';

const TimelineItem = ({ item, enableButton, index, totalItems }) => {
  const navigate = useNavigate();
  //const { userType } = useAuth(); // Access userType from auth context
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCreateOptionsClick = () => {
    navigate(`/create-booking-options-groups/${item.booking_id}`);
  };
  switch (item.itemType) {
    case 'message':
      return (
        <MsgTimelineItem
          item={item}
          enableButton={enableButton}
          onOptionClick={handleCreateOptionsClick}
          formatDate={formatDate}
          index={index}
        />
      );
    case 'options':
      if (item.optionGroups && item.optionGroups.length > 0) {
        //console.log(item.status_id);
        return (
          <BookingOptionGroup 
            optionGroup={item.optionGroups[0]} 
            statusId={item.status_id} 
          />
        );
      }
      return <p>No option groups available.</p>;
    case 'action':
      return (
        <ActionTimelineItem
          item={item}
          index={index}
          totalItems={totalItems} // Total number of items
        />
      );
    default:
      return <p>Unknown item type</p>;
  }
};

export default TimelineItem;
