import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './Booking.scss';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdCheckCircle } from 'react-icons/md';
import { statusIcons } from '../../data.ts';
import TimelineItem from '../../components/timelineItem/TimelineItem';
import { useAuth } from '../../contexts/authContext'; // Import the useAuth hook

const Booking = () => {
  const [bookingDetails, setBookingDetails] = useState({ statuses: [] });
  const { id } = useParams();
  const reversedStatuses = [...bookingDetails.statuses].reverse();
  const { t } = useTranslation();
  const { userType, isUserLoggedIn } = useAuth(); // Use the useAuth hook

  useEffect(() => {
    const fetchBookingDetails = async () => {
      const token = localStorage.getItem('token');
      if (!isUserLoggedIn || !token) {
        console.error('User is not authenticated.');
        return;
      }
      try {
        const response = await axios.post(`/get-booking-details`, { booking_id: id, token });
        if (response.data.success) {
          setBookingDetails(response.data.details);
        } else {
          console.error('Failed to fetch booking details');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (id) {
      fetchBookingDetails();
    }
  }, [id, isUserLoggedIn]); // Add isUserLoggedIn as a dependency
  
  const lastIndex = reversedStatuses.findIndex(status => 
    ['booking being processed', 'change requested'].includes(status.status.toLowerCase())
  );
  
  //console.log(lastIndex);
  return (
    <div className='booking'>
      <h1>{t("booking.pageTitle", "Booking Timeline")}</h1>
      <VerticalTimeline layout="1-column-left">
        {reversedStatuses.map((status, index) => {
          const { icon: Icon, style, tooltipKey } = statusIcons[status.status] || { icon: MdCheckCircle, style: { background: 'grey', color: '#fff', fontSize: '20px' } };
          // Ensure button is only enabled for the latest status and for operators
          const enableButton = index === lastIndex && userType === 'operator';
          return (
            <VerticalTimelineElement key={status.status_id} iconStyle={style} icon={<span title={t(tooltipKey)}><Icon style={{ fontSize: style.fontSize }} /></span>}>
              <TimelineItem 
                item={status} 
                enableButton={enableButton}
                index={index}
                totalItems={reversedStatuses.length} // Total number of items
              />
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
};

export default Booking;
