import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./Menu.scss";
import { menu } from "../../data.ts";
import { v4 as uuidv4 } from 'uuid';

const Menu = () => {
  const { t } = useTranslation();

  return (
    <div className="menu">
      {menu.map((item) => (
        <div className="item" key={uuidv4()}>
          <span className="title">{t(item.title)}</span>
          {item.listItems.map((listItem) => (
            <Link to={listItem.url} className="listItem" key={uuidv4()}>
              <img src={listItem.icon} alt="" />
              <span className="listItemTitle">{t(listItem.title)}</span>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Menu;