import './NewBooking.scss';
import BookingForm from '../../components/bookingForm/BookingForm';

const NewBooking = () => {
  return (
    <div className="newBooking">
        <div className="newBookingForm"><BookingForm /></div>
    </div>
  )
}

export default NewBooking
