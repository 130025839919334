import "./Footer.scss"
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <span>{t('app_name')}</span>
      <span>{t('app_copyrights')}</span>
    </div>
  )
};

export default Footer;
