import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext'; // Import useAuth hook
import './Login.scss';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { setIsUserLoggedIn, setUserType, setToken } = useAuth(); // Use the auth context

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/login`, { username, password });

      if (response.data.success && response.data.token) 
      {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userType', response.data.userType);
        setToken(response.data.token);  // Update token in context
        setIsUserLoggedIn(true); // Update login state using context
        setUserType(response.data.userType); // Update user type using context
      } 
      else 
      {
        console.log(response);
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      setError('Login failed. Please try again later.');
      console.error(error);
    }
  };

  return (
    <div className="loginContainer">
      <div className="login">
        <form onSubmit={handleLogin}>
          <input
            type="text"
            className="input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <input
            type="password"
            className="input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit" className="submitButton">Login</button>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
