import "./Mybookings.scss";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import DataTable from '../../components/dataTable/DataTable';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext'; // Adjust the import path as necessary

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const { t } = useTranslation();
  const { isUserLoggedIn, userType } = useAuth(); // Use the auth context

  useEffect(() => {
    console.log(123);
    const fetchBookings = async () => {
      if (isUserLoggedIn) {
        const token = localStorage.getItem('token');
        console.log(token);
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        try {
          const response = await axios.post(`/get-my-bookings`, { token }, config);
          console.log(response);
          if (Array.isArray(response.data.bookings)) {
            setBookings(response.data.bookings);
          } else {
            console.error('Bookings data is not an array:', response.data.bookings);
            setBookings([]);
          }
        } catch (error) {
          console.error('Error fetching bookings:', error);
          setBookings([]);
        }
      }
    };
  
    fetchBookings();
  }, [isUserLoggedIn]); // Add isUserLoggedIn as a dependency
  
  const columns = [
    { field: 'booking_id', headerName: 'ID', width: 100 },
    { field: 'from', headerName: 'From', width: 150 },
    { field: 'to', headerName: 'To', width: 150 },
    { field: 'departure_date', headerName: 'Departure Date', width: 200 },
    { field: 'return_date', headerName: 'Arrival Date', width: 200 },
    { field: 'booking_status', headerName: 'Status', width: 150 },
  ];

  return (
    <div className="myBookings">
      <div className="info">
        <h1>{t("myBookings.pageTitle", "My Bookings")}</h1>
        {userType === 'operator' && ( // Conditional rendering based on userType
          <Link to="/new-booking">
            <button>{t("myBookings.createNewBooking", "Create New Booking")}</button>
          </Link>
        )}
      </div>
      <DataTable 
        rows={bookings} 
        columns={columns} 
        getRowId={(row) => row.booking_id}
      />
    </div>
  );
};

export default MyBookings;
