import {
  MdCheckCircle,
  MdAutorenew,
  MdPresentToAll,
  MdChangeCircle,
  MdConfirmationNumber,
  MdFlight,
  MdDescription,
  MdPriceChange,
  MdBookOnline,
} from 'react-icons/md';

export const menu = [
    {
      id: 1,
      title: "menu.main",
      listItems: [
        {
          id: 1,
          title: "menu.dashboard",
          url: "/",
          icon: "home.svg",
        },
      ],
    },
    {
      id: 2,
      title: "menu.bookings",
      listItems: [
        {
          id: 1,
          title: "menu.new_booking",
          url: "/new-booking",
          icon: "home.svg",
        },
        {
          id: 2,
          title: "menu.my_bookings",
          url: "/my-bookings",
          icon: "home.svg",
        },
      ],
    },
    {
      id: 3,
      title: "menu.billing",
      listItems: [
        {
          id: 1,
          title: "menu.add-balance",
          url: "/add-balance",
          icon: "home.svg",
        },
        {
          id: 2,
          title: "menu.list-transactions",
          url: "/list-transactions",
          icon: "home.svg",
        },
      ],
    },
    {
      id: 4,
      title: "menu.settings",
      listItems: [
        {
          id: 1,
          title: "menu.profile",
          url: "/profile",
          icon: "home.svg",
        },
        {
          id: 1,
          title: "menu.my-settings",
          url: "/my-settings",
          icon: "home.svg",
        },
      ],
    },
    {
      id: 5,
      title: "menu.reports",
      listItems: [
        {
          id: 1,
          title: "menu.my-reports",
          url: "/my-reports",
          icon: "home.svg",
        },
      ],
    },
  ];

  export const operatorMenu = [
    {
      id: 1,
      title: "menu.main",
      listItems: [
        {
          id: 1,
          title: "menu.dashboard",
          url: "/",
          icon: "home.svg",
        },
      ],
    },
    {
      id: 2,
      title: "menu.bookings",
      listItems: [
        {
          id: 1,
          title: "menu.new_booking",
          url: "/new-booking",
          icon: "home.svg",
        },
        {
          id: 2,
          title: "menu.my_bookings",
          url: "/my-bookings",
          icon: "home.svg",
        },
      ],
    },
  ];

  export const statusIcons = 
    {
      'booking submitted': 
      { 
        icon: MdCheckCircle, 
        tooltipKey:'booking.status_tooltip.booking_submitted', 
        style: 
        { 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'booking being processed': 
      { 
        icon: MdAutorenew, 
        tooltipKey:'booking.status_tooltip.booking_being_processed', 
        style: 
        { 
          background: 'rgb(103, 58, 183)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'option group presented': 
      { 
        icon: MdPresentToAll, 
        tooltipKey:'booking.status_tooltip.option_group_presented', 
        style: { 
          background: 'rgb(0, 150, 136)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'change requested': 
      { 
        icon: MdChangeCircle, 
        tooltipKey:'booking.status_tooltip.change_requested', 
        style: 
        { 
          background: 'rgb(255, 235, 59)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'confirmed by client': 
      { 
        icon: MdConfirmationNumber, 
        tooltipKey:'booking.status_tooltip.confirmed_by_client', 
        style: 
        { 
          background: 'rgb(76, 175, 80)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'passengers info requested': 
      { 
        icon: MdFlight, 
        tooltipKey:'booking.status_tooltip.passengers_info_requested', 
        style: 
        { 
          background: 'rgb(33, 33, 33)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'documents received': 
      { 
        icon: MdDescription, 
        tooltipKey:'booking.status_tooltip.documents_received', 
        style: 
        { 
          background: 'rgb(255, 87, 34)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'price changed': 
      { 
        icon: MdPriceChange, 
        tooltipKey:'booking.status_tooltip.price_changed', 
        style: 
        { 
          background: 'rgb(0, 188, 212)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
      'booked': 
      { 
        icon: MdBookOnline, 
        tooltipKey:'booking.status_tooltip.booked', 
        style: 
        { 
          background: 'rgb(156, 39, 176)', 
          color: '#fff', 
          fontSize: '20px' 
        } 
      },
    }
    export const airports = 
    [
      { label: 'Hartsfield–Jackson Atlanta International Airport', id: 'ATL' },
      { label: 'Beijing Capital International Airport', id: 'PEK' },
      { label: 'Los Angeles International Airport', id: 'LAX' },
      // Add more airports as needed...
    ];
  
    export const airlines = 
    [
      { label: 'Delta Air Lines', code: 'DL' },
      { label: 'American Airlines', code: 'AA' },
      { label: 'United Airlines', code: 'UA' },
      // Include more airlines as necessary...
    ];