import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './ActionTimelineItem.scss';

const ActionTimelineItem = ({ item, index, totalItems }) => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Define a function to handle the button click that navigates to the passport upload page
  const handleUploadClick = () => {
    navigate(`/booking/${item.booking_id}/upload-passports`); // Navigate to the passport upload page
  };

  //we are using this disabled={index !== 0} because the passed items array is reversed so we want the button to be enabled only if the item's index is 0 which means it is the first item in the array which means it is the last item since the array is reveresed
  return (
    
    <div className="actionTimelineItem">
      <p className="statusDescription">{item.status}</p>
      <p className="statusComment">{item.comment}</p>
      <button onClick={handleUploadClick} disabled={index !== 0}>Upload Passports Scans</button> {/* Use handleUploadClick function on button click */}
    </div>
  );
}

export default ActionTimelineItem;
