// PreviewPassports.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ImageModal from '../../components/previewImageModal/PreviewImageModal';  // Import the modal component
import './PreviewPassports.scss'
const PreviewPassports = () => {
    const { bookingId } = useParams();
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalImage, setModalImage] = useState(null);  // State to control modal image URL

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');

            try {
                const response = await axios.post(`/get-passport-images`, 
                    { booking_id: bookingId, token: token } // Include token in the body
                );

                if (response.data.success && response.data.images.length > 0) {
                    const imageBlobs = await Promise.all(response.data.images.map(async (imgPath) => {
                        const imgResponse = await axios.post(
                            `/get-passport-image`, 
                            { path: imgPath, token: token }, // Include token in the body
                            { responseType: 'blob' }
                        );
                        return URL.createObjectURL(new Blob([imgResponse.data]));
                    }));
                    setImages(imageBlobs);
                } else {
                    console.error('Error fetching images:', response.data.message);
                }
            } catch (error) {
                console.error('Failed to fetch images', error);
            }
            setLoading(false);
        };

        if (bookingId) {
            fetchImages();
        }
    }, [bookingId]);

    const handleImageClick = (imgUrl) => {
        setModalImage(imgUrl);  // Set the clicked image URL to open it in the modal
    };

    if (loading) return <p>Loading...</p>;
    if (!images.length) return <p>No images found.</p>;

    return (
        <div className="imageGrid">
            {images.map((imgUrl, index) => (
                <img key={index} src={imgUrl} alt={`Passport Scan ${index + 1}`} onClick={() => setModalImage(imgUrl)} />
            ))}
            {modalImage && <ImageModal imgUrl={modalImage} onClose={() => setModalImage(null)} />}
        </div>
    );
};

export default PreviewPassports;
