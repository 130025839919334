import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./contexts/authContext"; // Import useAuth hook
import "./styles/global.scss";
import './config/i18n';
import Navbar from "./components/navbar/Navbar";
import Menu from "./components/menu/Menu";
import Footer from "./components/footer/Footer";
import NewBooking from "./pages/newBooking/NewBooking";
import Booking from "./pages/booking/Booking";
import MyBookings from "./pages/myBookings/MyBookings";
import OperatorMenu from "./components/operatorMenu/OperatorMenu";
import CreateBookingOptionsGroup from "./pages/createBookingOptionsGroup/CreateBookingOptionsGroup";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import PassportsUpload from "./pages/passportsUpload/PassportsUpload";
import PreviewPassports from './pages/previewPassports/PreviewPassports';
import TicketsUpload from "./pages/ticketsUpload/TicketsUpload";



function App() {
  const { isUserLoggedIn, isLoading, userType } = useAuth(); // Destructure isLoading

  useEffect(() => {
    // Log the current authentication state
    //console.log('isUserLoggedIn:', isUserLoggedIn);
    //console.log('userType:', userType);
  }, [isUserLoggedIn, userType]);

  if (isLoading) {
    return <div>Loading...</div>; // Or some loading spinner
  }
  
  const Layout = ({ userType }) => (
    <div className="main">
      <Navbar />
      <div className="container">
        <div className="menuContainer">
          {userType === 'operator' ? <OperatorMenu /> : <Menu />}
        </div>
        <div className="contentContainer">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: isUserLoggedIn ? <Layout userType={userType} /> : <Navigate to="/login" replace />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/new-booking", element: <NewBooking /> },
        { path: "/booking/:id", element: <Booking /> },
        { path: "/my-bookings", element: <MyBookings /> },
        {
          path: "/booking/:id/upload-passports",
          element: isUserLoggedIn ? <PassportsUpload /> : <Navigate to="/login" replace />
        },
        {
          path: "/create-booking-options-groups/:id",
          element: isUserLoggedIn && userType === 'operator' ? <CreateBookingOptionsGroup /> : <Navigate to="/login" replace />
        },
        {
          path: "/preview-passports/:bookingId",
          element: isUserLoggedIn && userType === 'operator' ? <PreviewPassports /> : <Navigate to="/login" replace />
        },
        {
          path: "/booking/:id/upload-tickets",
          element: isUserLoggedIn ? <TicketsUpload /> : <Navigate to="/login" replace />
        },
      ],
    },
    {
      path: "/login",
      element: !isUserLoggedIn ? <Login /> : <Navigate to="/" replace />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
