// TicketsUpload.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import './TicketsUpload.scss';
import { Typography } from '@mui/material';

const TicketsUpload = () => {
    const { id: bookingId } = useParams();
    const navigate = useNavigate();
    const [passengers, setPassengers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Assume an endpoint that returns the number of tickets needed per booking
        const fetchPassengerDetails = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.post(`/passengers-count`, 
                    { booking_id: bookingId, token: token } // Include token in the body
                );
                const { number_of_adults, number_of_children, number_of_infants } = response.data.passengersDetails;
                const passengerCount = number_of_adults + number_of_children + number_of_infants;
                setPassengers(Array.from({ length: passengerCount }, () => ({ file: null })));
            } catch (error) {
                console.error('Failed to fetch passengers', error);
                setMessage('Failed to fetch passenger details');
                setOpenSnackbar(true);
            }
        };

        fetchPassengerDetails();
    }, [bookingId]);

    const handleFileChange = (file, index) => {
        const newPassengers = [...passengers];
        newPassengers[index].file = file;
        setPassengers(newPassengers);
    };

    const handleRemoveFile = (index) => {
        const newPassengers = [...passengers];
        newPassengers[index].file = null;
        setPassengers(newPassengers);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('booking_id', bookingId);
        passengers.forEach((passenger, index) => {
            if (passenger.file) {
                formData.append(`ticket${index}`, passenger.file);
            }
        });

        const token = localStorage.getItem('token');
        formData.append('token', token); // Include token in the body
        try {
            const response = await axios.post(`/upload-tickets`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.success) {
                setMessage('Tickets uploaded successfully');
                navigate(`/booking/${bookingId}`);
            } else {
                throw new Error(response.data.message || 'Upload failed');
            }
        } catch (error) {
            console.error('Upload failed:', error.response ? error.response.data : error.message);
            setMessage(error.response && error.response.data ? error.response.data.message : 'Upload failed');
        } finally {
            setLoading(false);
            setOpenSnackbar(true);
        }
    };

    return (
        <div className="ticketsUpload">
            {passengers.map((passenger, index) => (
                <div key={index} className="uploadRow">
                    <Typography className="passengerLabel">Passenger {index + 1}</Typography>
                    {passenger.file ? (
                        <>
                            <img src={URL.createObjectURL(passenger.file)} alt="Ticket scan preview" />
                            <IconButton onClick={() => handleRemoveFile(index)} color="secondary">
                                <DeleteIcon />
                            </IconButton>
                        </>
                    ) : (
                        <Button variant="outlined" component="label">
                            Upload Ticket
                            <input
                                type="file"
                                hidden
                                accept="image/jpeg, application/pdf"
                                onChange={(event) => handleFileChange(event.target.files[0], index)}
                            />
                        </Button>
                    )}
                </div>
            ))}
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading || passengers.some(p => !p.file)}>
                Submit Tickets
            </Button>
            {loading && <LinearProgress />}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message={message} />
        </div>
    );
};

export default TicketsUpload;
