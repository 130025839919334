import React, { useEffect } from 'react';
import './PreviewImageModal.scss';

const ImageModal = ({ imgUrl, onClose }) => {
    // Handle escape key press to close modal
    useEffect(() => {
        const handleEscapePress = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        // Attach event listener when component mounts
        document.addEventListener('keydown', handleEscapePress);

        // Cleanup to remove event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, [onClose]); // Dependencies array to re-apply if onClose changes.

    // Early return moved after hooks
    if (!imgUrl) return null;

    // Close modal when clicking outside the image content
    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="modalBackdrop" onClick={handleBackdropClick}>
            <div className="modalContent">
                <img src={imgUrl} alt="Passport Detail" />
                <button onClick={onClose} className="modalCloseButton">×</button>
            </div>
        </div>
    );
};

export default ImageModal;
