import "./Navbar.scss"
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext'; // Adjust the import path as necessary


const Navbar = () => {
  const { t } = useTranslation();  // Add this line to use the translation function
  const { logout } = useAuth();  // Use the logout function from the context

  return (
    <div className="navbar">
      <div className="logo">
        <img src="logo.svg" alt=""/>
        <span>{t('app_name')}</span>
      </div>
      <div className="icons">
        <div className="notification">
          <img src="/notifications.svg" alt=""/>
          <span>1</span>
        </div>
        <img src="/settings.svg" alt="" className="icon" />
        <div className="user">
          <img src="https://images.pexels.com/photos/11038549/pexels-photo-11038549.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load" alt=""/>
          <span>Jane</span>
        </div>
        <img src="/logout.svg" alt="Logout" className="icon" onClick={logout} /> {/* Use the logout function on click */}
      </div>
    </div>
  )
};

export default Navbar;
