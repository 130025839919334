// Importing necessary hooks and createContext function from React
import React, { createContext, useState, useContext, useEffect } from 'react';

// Creating a context for authentication state
const AuthContext = createContext(null);

/**
 * Provides an authentication context to its child components.
 * It maintains the authentication state and provides a method to logout.
 *
 * @param {Object} props - Props passed to the AuthProvider.
 * @param {React.ReactNode} props.children - Child components of the AuthProvider.
 */
export const AuthProvider = ({ children }) => {
    // State to hold whether the user is logged in
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    // State to store the type of user (e.g., 'operator', 'client')
    const [userType, setUserType] = useState('');
    // State to indicate whether the auth status is being checked
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(null);  // Manage token in state

    // useEffect to check the user's authentication status when the component mounts
    useEffect(() => {
        const storedToken = localStorage.getItem('token'); // Retrieve the auth token from localStorage
        const storedUserType = localStorage.getItem('userType'); // Retrieve the user type from localStorage

        if (storedToken) 
        {
            setToken(storedToken);  // Set token from storage
            setIsUserLoggedIn(true);
            setUserType(storedUserType || '');
        } 
        else 
        {
            setIsUserLoggedIn(false);
            setUserType('');
        }
        setIsLoading(false);
    }, []);

    // Function to handle user logout
    const logout = () => {
        localStorage.removeItem('token'); // Remove the token from localStorage
        localStorage.removeItem('userType'); // Remove the user type from localStorage
        setToken(null);  // Clear token state
        setIsUserLoggedIn(false); // Update the isLoggedIn state
        setUserType(''); // Clear the userType state
    };

    // Providing the authentication state and logout function to child components
    return (
        <AuthContext.Provider value={{ 
            isUserLoggedIn, 
            setIsUserLoggedIn, 
            userType, 
            setUserType, 
            token,
            setToken,  // Make token available in context
            isLoading, 
            logout 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

/**
 * Custom hook to use the auth context.
 * Allows easy access to the authentication state and functions from any component.
 *
 * @returns {Object} The authentication context containing state and functions.
 */
export const useAuth = () => useContext(AuthContext);
